import React from 'react';
import bemCn from 'bem-cn';
import { facebookLink, instagramLink, tiktokLink, youtubeLink } from '../../consts/link.consts';
import imgTTColor from '../../assets/img/tiktok-icon.png?sizes[]=128';
import PictureSet from '../picture-set';
import fbCircle from '../../assets/img/fb-circle.png?sizes[]=128';
import ytCircle from '../../assets/img/youtube-circle.png?sizes[]=128';
import igCircle from '../../assets/img/ig-circle.png?sizes[]=128';
import imgTTBg from '../../assets/img/tiktok-bg.png?sizes[]=600,sizes[]=768,sizes[]=992';
import './style.scss';

const b = bemCn('tiktok-widget');

const SOCIAL_MEDIAS = [
    {
        img: igCircle,
        url: instagramLink,
    },
    {
        img: fbCircle,
        url: facebookLink,
    },
    {
        img: ytCircle,
        url: youtubeLink,
    },
];

const TiktokWidget = ({ fixed }) => (
    <div className={b.mix(fixed ? '' : 'jumbo')}>
        <div className={b('left')}>
            <PictureSet imgs={imgTTBg} alt="Tag @sniffspot in your posts and stories, we often reshare them!" />
        </div>
        <div className={b('right')}>
            <h3>Find Sniffspot on TikTok!</h3>
            <div>
                <a className={b('btn')} href={tiktokLink} target="_blank" rel="noreferrer">
                    <PictureSet imgs={imgTTColor} width={24} height={24} alt="tiktok logo" />
                    <span className="snif-medium">Follow @sniffspot</span>
                </a>
            </div>
            <div className="snif-s2 text-deep mt-3 text-center text-md-start">Also find us on</div>
            <div className={b('icons')}>
                {SOCIAL_MEDIAS.map((s, i) => (
                    <a key={i} href={s.url} target="_blank" rel="noreferrer">
                        <PictureSet imgs={s.img} width={40} height={40} alt={s.url} />
                    </a>
                ))}
            </div>
        </div>
    </div>
);

export default TiktokWidget;
