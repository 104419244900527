import React from 'react';
import { RouteFormatter } from '../routes';

export const toColumns = (cities, col = 5.0) => {
    const n = [];
    const cols = [];

    for (let i = 0; i < col; i++) {
        cols.push(i);
    }

    let remains = cities.length;

    for (let i = 0; i < cols.length; i++) {
        const col_size = cities.length < 10 ? 2 : Math.ceil(remains / (col - i));
        n.push(col_size);
        remains = remains - col_size;
    }

    let x = 0;

    return n.map((m) => cities.slice(x, (x += m)));
};

export const cityLink = (cityName, cityUrl, i) => {
    const href = RouteFormatter.listings({ first: cityUrl });

    return (
        <a key={i} href={href}>
            <h3 className="snif-p snif-regular mb-1">{cityName}</h3>
        </a>
    );
};

export const stateLink = (stateName, stateUrl, j) => {
    const href = RouteFormatter.listings({ first: stateUrl });

    return (
        <a key={j} href={href}>
            <h3 className="snif-p snif-regular mb-1">{stateName}</h3>
        </a>
    );
};
